<template>
  <div>
    <div class="d-flex mb-3 justify-content-center">
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          1
        </div>
        Create Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          2
        </div>
        Key in Details
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          3
        </div>
        Finalize Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          4
        </div>
        Proceed Payment
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-card>
        <div class="d-flex justify-content-center align-items-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="CalendarIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Delivery Date</h3>
          <div class="w-75">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Delivery Date"
                rules="required"
              >
                <flat-pickr
                  v-model="form.delivery_datetime"
                  class="form-control mt-1"
                  placeholder="Choose Date"
                  readonly="readonly"
                  :config="{
                    minDate: 'today',
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center align-items-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="TruckIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Origin Point</h3>
          <div class="w-75">
            <div>
              <p
                type="text"
                class="form-control mr-2 mt-1 d-flex align-items-center"
              >
                {{ form.origin_point.address }}
              </p>
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="HomeIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Sender's Details</h3>
          <div class="w-75">
            <div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's First Name"
                    rules="required"
                  >
                    <input
                      v-model="form.sender_first_name"
                      type="text"
                      class="form-control mr-2"
                      placeholder="First Name"
                      aria-label="First Name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Last Name"
                    rules="required"
                  >
                    <input
                      v-model="form.sender_last_name"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Last Name"
                      aria-label="Last Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Sender's Phone Number"
                  rules="required"
                >
                  <input
                    v-model="form.sender_phone_number"
                    type="text"
                    class="form-control"
                    placeholder="Phone Number"
                    aria-label="Phone Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Unit No."
                    rules="required"
                  >
                    <input
                      v-model="form.sender_unit_number"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Unit No."
                      aria-label="Unit No."
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Address Line 1"
                    rules="required"
                  >
                    <input
                      v-model="form.sender_address_line_1"
                      type="text"
                      class="form-control"
                      placeholder="Address Line 1"
                      aria-label="Address Line 1"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Sender's Address Line 2"
                  rules="required"
                >
                  <input
                    v-model="form.sender_address_line_2"
                    type="text"
                    class="form-control"
                    placeholder="Address Line 2"
                    aria-label="Address Line 2"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Postcode"
                    rules="required"
                  >
                    <input
                      v-model="form.sender_postcode"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Postcode"
                      aria-label="Postcode"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Area"
                    rules="required"
                  >
                    <input
                      v-model="form.sender_area"
                      type="text"
                      class="form-control"
                      placeholder="Area"
                      aria-label="Area"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's State"
                    rules="required"
                  >
                    <select
                      v-model="form.sender_state"
                      class="form-control form-select rounded mr-2"
                    >
                      <option value="" disabled selected>State</option>
                      <option v-for="(state, index) in state" :key="index">
                        {{ state.state_name }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Country"
                    rules="required"
                  >
                    <select
                      v-model="form.sender_country"
                      class="form-control form-select rounded"
                    >
                      <option value="" disabled selected>Country</option>
                      <option v-for="(country, index) in country" :key="index">
                        {{ country.value }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="HomeIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Recipient's Details</h3>
          <div class="w-75">
            <div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's First Name"
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_first_name"
                      type="text"
                      class="form-control mr-2"
                      placeholder="First Name"
                      aria-label="First Name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Last Name"
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_last_name"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Last Name"
                      aria-label="Last Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Recipient's Phone Number"
                  rules="required"
                >
                  <input
                    v-model="form.recipient_phone_number"
                    type="text"
                    class="form-control"
                    placeholder="Phone Number"
                    aria-label="Phone Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Unit No."
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_unit_number"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Unit No."
                      aria-label="Unit No."
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Address Line 1"
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_address_line_1"
                      type="text"
                      class="form-control"
                      placeholder="Address Line 1"
                      aria-label="Address Line 1"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Recipient's Address Line 2"
                  rules="required"
                >
                  <input
                    v-model="form.recipient_address_line_2"
                    type="text"
                    class="form-control"
                    placeholder="Address Line 2"
                    aria-label="Address Line 2"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Postcode"
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_postcode"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Postcode"
                      aria-label="Postcode"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Area"
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_area"
                      type="text"
                      class="form-control"
                      placeholder="Area"
                      aria-label="Area"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's State"
                    rules="required"
                  >
                    <select
                      v-model="form.recipient_state"
                      class="form-control form-select rounded mr-2"
                    >
                      <option value="" disabled selected>State</option>
                      <option v-for="(state, index) in state" :key="index">
                        {{ state.state_name }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Country"
                    rules="required"
                  >
                    <select
                      v-model="form.recipient_country"
                      class="form-control form-select rounded"
                    >
                      <option value="" disabled selected>Country</option>
                      <option v-for="(country, index) in country" :key="index">
                        {{ country.value }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="BoxIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Product Details</h3>
          <div class="w-75">
            <div class="d-flex">
              <div class="mr-2">
                <validation-provider
                  #default="{ errors }"
                  name="Product Categories"
                  rules="required"
                >
                  <h4>Product Categories</h4>
                  <b-form-group
                    v-for="(product, index) in product"
                    :key="index"
                  >
                    <b-form-radio
                      v-model="form.product_category"
                      name="product"
                      :value="product.value"
                      >{{ product.value }}</b-form-radio
                    >
                  </b-form-group>
                  <input
                    v-model="product_category"
                    type="text"
                    class="form-control mt-1"
                    placeholder="Others"
                    aria-label="Others"
                    :disabled="form.product_category !== 'Others'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <div class="mr-2">
                <validation-provider
                  #default="{ errors }"
                  name="Packaging"
                  rules="required"
                >
                  <h4>Packaging</h4>
                  <b-form-group
                    v-for="(packaging, index) in packaging"
                    :key="index"
                  >
                    <b-form-radio
                      v-model="form.packaging"
                      name="packaging"
                      :value="packaging.value"
                      >{{ packaging.value }}</b-form-radio
                    >
                  </b-form-group>
                  <input
                    v-model="optionPackaging"
                    type="text"
                    class="form-control mt-1"
                    placeholder="Others"
                    aria-label="Others"
                    :disabled="form.packaging !== 'Others'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="mr-2">
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  rules="required"
                >
                  <h4>Remarks</h4>
                  <b-form-group
                    v-for="(remarks, index) in remarks"
                    :key="index"
                  >
                    <b-form-radio
                      v-model="form.remarks"
                      name="remarks"
                      :value="remarks.value"
                      >{{ remarks.value }}</b-form-radio
                    >
                  </b-form-group>
                  <input
                    v-model="optionRemarks"
                    type="text"
                    class="form-control mt-1"
                    placeholder="Others"
                    aria-label="Others"
                    :disabled="form.remarks !== 'Others'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="w-50">
                <validation-provider
                  #default="{ errors }"
                  name="Quantity"
                  rules="required|positive"
                >
                  <input
                    v-model="form.quantity"
                    type="text"
                    class="form-control mt-1"
                    placeholder="Quantity"
                    aria-label="Quantity"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="Temperature"
                  rules="required"
                >
                  <select
                    v-model="form.temperature"
                    class="form-control form-select mt-1"
                  >
                    <option value="" disabled selected>
                      Select Temperature
                    </option>
                    <option v-for="(sample, index) in sample" :key="index">
                      {{ sample.value }}
                    </option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="Size"
                  rules="required"
                >
                  <cleave
                    id="cardNumber"
                    v-model="form.item_size"
                    class="form-control mt-1"
                    :raw="false"
                    :options="options.boxSize"
                    placeholder="Length (cm) x Width (cm) x Height (cm)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="Weight (kg)"
                  rules="required|positive"
                >
                  <input
                    v-model="form.item_weight"
                    type="text"
                    class="form-control mt-1"
                    placeholder="Weight (kg)"
                    aria-label="Weight (kg)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="SKU"
                  rules="required"
                >
                  <input
                    v-model="form.sku"
                    type="text"
                    class="form-control mt-1"
                    placeholder="SKU"
                    aria-label="SKU"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </b-card>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <b-button
          v-b-modal.modal-center
          variant="gradient-primary"
          class="box-shadow-1 px-5"
          @click="onSubmit"
          >Submit</b-button
        >
      </div>
    </validation-observer>
    <div>
      <b-modal ref="my-modal" hide-footer hide-header title="Alert!">
        <div class="d-block text-center my-5">
          <h4>
            Sorry, States Not Under Coverage.
          </h4>
        </div>
        <b-button class="mt-2" variant="primary" block @click="hideModal()"
          >Confirm</b-button
        >
      </b-modal>
    </div>
    <div>
      <b-modal ref="confirmation-modal" hide-footer hide-header title="Alert!">
        <div class="d-block text-center my-5">
          <h4>{{ this.doubleCharge }}</h4>
        </div>
        <div class="d-flex">
          <b-button
            class="mr-2 mt-2"
            variant="light"
            block
            @click="continueEditing()"
            >Edit Order</b-button
          >
          <b-button
            class="mr-2 mt-2"
            variant="primary"
            block
            @click="calculateOrder()"
            >Confirm</b-button
          >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from '@axios';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import flatPickr from 'vue-flatpickr-component';
import Cleave from 'vue-cleave-component';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    extend,
    flatPickr,
    Cleave,
    ToastificationContent,
  },
  data() {
    return {
      form: {
        origin_point: '',
        temperature: '',
        sender_state: '',
        sender_country: '',
        recipient_state: '',
        recipient_country: '',
        order_type: 'Outlet',
        payment_method: 'LOGPOINT',
        double_charge: 0,
      },
      optionInput: 0,
      double_charge: 0,
      product_category: '',
      optionPackaging: '',
      optionRemarks: '',
      doubleCharge: 'Your order will be double charged.',
      options: {
        boxSize: {
          delimiters: [' x ', ' x ', ' x '],
          blocks: [2, 2, 2],
          uppercase: true,
        },
      },
      address: [],
      state: [],
      country: [
        {
          title: 'Malaysia',
          value: 'Malaysia',
        },
        {
          title: 'Singapore',
          value: 'Singapore',
        },
      ],
      product: [
        {
          title: 'Frozen Food',
          value: 'Frozen Food',
        },
        {
          title: 'Frozen Meat',
          value: 'Frozen Meat',
        },
        {
          title: 'Fruits',
          value: 'Fruits',
        },
        {
          title: 'Vegetables',
          value: 'Vegetables',
        },
        {
          title: 'Dry Goods',
          value: 'Dry Goods',
        },
        {
          title: 'Others',
          value: 'Others',
        },
      ],
      packaging: [
        {
          title: 'Carton Box',
          value: 'Carton Box',
        },
        {
          title: 'Foam Box',
          value: 'Foam Box',
        },
        {
          title: 'Plastic',
          value: 'Plastic',
        },
        {
          title: 'Crate',
          value: 'Crate',
        },
        {
          title: 'Normal Box',
          value: 'Normal Box',
        },
        {
          title: 'Others',
          value: 'Others',
        },
      ],
      remarks: [
        {
          title: 'Fragile',
          value: 'Fragile',
        },
        {
          title: 'Contains Alcohol',
          value: 'Contains Alcohol',
        },
        {
          title: 'Special Handling',
          value: 'Special Handling',
        },
        {
          title: 'Non Halal Items',
          value: 'Non Halal Items',
        },
        {
          title: 'Others',
          value: 'Others',
        },
      ],
      sample: [
        {
          title: 'Ambiance',
          value: 'Ambiance',
        },
        {
          title: 'Chill',
          value: 'Chill',
        },
        {
          title: 'Frozen',
          value: 'Frozen',
        },
      ],
    };
  },
  mounted() {
    this.address = JSON.parse(localStorage.getItem('userData'));
    this.form.origin_point = this.address.branch;
    this.$http.get('state').then((response) => {
      this.state = response.data.data;
    });
  },
  created() {
    if (this.$route.params.data) {
      this.form = this.$route.params.data;
    }
  },
  methods: {
    onSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.double_charge = 0;
          if (this.form.item_weight > 20) {
            this.doubleCharge += ' Weight exceeds limit.';
            this.double_charge = 1;
          }
          const size = this.form.item_size.split('x');
          if (
            parseInt(size[0]) > 0 &&
            parseInt(size[1]) > 0 &&
            parseInt(size[2]) > 0
          ) {
            if (
              parseInt(size[0]) > 51 ||
              parseInt(size[1]) > 39 ||
              parseInt(size[2]) > 36
            ) {
              this.doubleCharge += ' Size exceeds limit.';
              this.double_charge = 1;
            }
            if (this.doubleCharge != 'Your order will be double charged.') {
              this.$refs['confirmation-modal'].show();
            } else {
              this.calculateOrder();
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please input the correct size of your item',
                icon: 'XIcon',
                variant: 'warning',
              },
            });
          }
        }
      });
    },
    calculateOrder() {
      if (this.form.product_category == 'Others') {
        this.form.product_category = this.product_category;
      }
      if (this.form.packaging == 'Others') {
        this.form.packaging = this.optionPackaging;
      }
      if (this.form.remarks == 'Others') {
        this.form.remarks = this.optionRemarks;
      }
      this.$http
        .get(
          `quote?state_origin=${this.form.sender_state}&state_destination=${this.form.recipient_state}`
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === true) {
              if (this.double_charge == 1) {
                this.form.double_charge = response.data.quotation.double_charge;
                this.form.unit_price = response.data.quotation.double_charge;
              } else {
                this.form.unit_price = response.data.quotation.unit_price;
              }
              this.form.tax = response.data.quotation.tax_charge;
              this.form.tax_percentage = this.form.tax / 100;
              this.form.amount =
                this.form.quantity * this.form.unit_price +
                this.form.quantity *
                  this.form.unit_price *
                  this.form.tax_percentage;
              this.$router.push({
                name: 'Outlet-finalize',
                params: {
                  data: this.form,
                  double_charge: this.double_charge,
                },
              });
            } else {
              this.$refs['my-modal'].show();
            }
          }
        });
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    continueEditing() {
      this.$refs['confirmation-modal'].hide();
      this.doubleCharge = 'Your order will be double charged.';
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.icon {
  background-color: #0f4392;
  height: 50px;
  width: 50px;
}

.text {
  width: 15rem;
}

.notActive {
  background-color: rgba(211, 18, 100, 0.58);
}
</style>
